:root {
  --app-color: #800020;
}
.text-app {
  color: var(--app-color) !important;
}
.text-green {
  color :#006641  !important;
}
.text-red {
  color :red;
}
.text-yellow {
  color: #ffc64a;
}
.bg-app {
  background-color: var(--app-color);
}
.app-border {
  border-color: var(--app-color) !important;
}
.border-green {
  border-color: #006641 !important;
}
.border-gray {
  border-color: #ced4da !important;
}
.svg-icon {
  display: inline-flex;
  align-self: center;
}

.svg-icon svg {
  height: 1em;
  width: 1em;
}

.svg-icon.svg-baseline {
  position: relative;
  top: 0.125em;
}

.svg-icon-lg svg {
  height: 1.25em;
  width: 1.25em;
}

.svg-icon-lg.svg-baseline svg {
  position: relative;
  top: 0.15675em;
}

.svg-icon-sm svg {
  height: 0.8em;
  width: 0.8em;
}

.svg-icon-sm.svg-baseline svg {
  position: relative;
  top: 0em;
}

.svg-disabled svg {
  fill: gray;
}

.svg-white svg {
  fill: white;
}

.shadow-lg {
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.toast-list {
  width: 100%;
  z-index: 40;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 300px;
}

.w-600 {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}
.w-30-p {
  width: 30%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} 
.MuiTab-textColorPrimary.Mui-selected{
  color: var(--app-color) !important;
}
.PrivateTabIndicator-colorPrimary-2 {
  background-color: var(--app-color) !important;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.shadow-inside {
  box-shadow: inset 0 0rem 3rem rgb(0 0 0 / 10%) !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--app-color) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: var(--app-color) !important;
}

.dk-table td {
  vertical-align: middle !important;
}

.icon-md {
  height: 1.2rem;
  width: 1.2rem;
}

.tooltipClass {
  max-width: 350px;
}
